<template>
  <div
    class="profile"
    @mouseenter="showDrop=!showDrop"
    @mouseleave="showDrop=false"
  >
    <div class="profile__icon">
      <profileSVG v-if="!user || !user.avatar" />
      <img
        v-else
        class="profile__photo"
        :src="user.avatar"
        alt="avatar"
      >
    </div>

    <span class="profile__name">{{ fullName }}</span>
    <button
      class="profile__btn"
    >
      <div
        v-if="showDrop"
        class="profile__drop"
      >
        <span
          @click.prevent="logout"
        >
          {{ options }}
        </span>
      </div>
    </button>
  </div>
</template>

<script>
import profileSVG from '../assets/images/Profile/profile.svg?inline'

export default {
  name: 'Profile',
  components: {
    profileSVG,
  },
  props: {
    user: {
      type: Object,
      default: Object
    }
  },
  data() {
    return {
      showDrop: false,
      value: null,
      options: 'Выйти'
    }
  },
  computed: {
    fullName() {
      if (this.user) {
        return `${this.user.lastname || ''} ${this.user.firstname || ''} ${this.user.fatherland || ''}`
      } else {
        return 'Тестов Т.Т.'
      }
    },
  },
  mounted() {
    console.log(this.user, 'profile')
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
      this.$router.push('/authorization')
    },
  }
}
</script>

<style lang="scss">


</style>
