<template>
  <main class="main">
    <aside class="sidebar">
      <nav
        class="sidebar-left"
      >
        <div class="logo">
          logo
        </div>
        <ul
          v-for="role in loggedIn.roles"
          :key="role.name"
          class="sidebar-left__list"
        >
          <li
            v-for="(currentPage) in pages"
            :key="currentPage.path"
            class="sidebar-left__item"
          >
            <router-link
              v-if="currentPage.show.includes(role.name)"
              :to="currentPage.path"
            >
              <img
                :src="require(`../assets/images/SidebarLeft/${currentPage.img}`)"
                :alt="currentPage.title"
              >
            </router-link>
          </li>
        </ul>
      </nav>
    </aside>
    <aside
      v-if="page"
      class="sidebar-right"
    >
      <nav>
        <h3
          :style="{marginTop: page.margin + 'px'}"
          class="sidebar-right__title"
        >
          {{ page.title }}
        </h3>
        <ul
          class="sidebar-right__list"
        >
          <li
            v-for="subpage in page.subpage"
            :key="subpage.path"
            class="sidebar-right__item"
          >
            <router-link
              class="sidebar-right__link"
              :to="subpage.path"
            >
              {{ subpage.title }}
            </router-link>
          </li>
        </ul>
      </nav>
    </aside>
    <div style="width: 100%">
      <header
        style="display: flex;justify-content: flex-end;"
      >
        <app-profile
          :user="user"
        />
      </header>
      <slot />
    </div>
  </main>
</template>

<script>
import AppProfile from "../components/Profile";
import {mapGetters} from "vuex";

export default {
  name: "DefaultLayout",
  components: {
    AppProfile
  },
  data() {
    return {
      counter: 0,
      page: null,
      pages: [
        {
          title: 'Списки',
          path: '/lists',
          img: 'lists.svg',
          margin: 125,
          show: ['Admin'],
          subpage: [
            {
              title: 'Персонал',
              path: '/lists/staff'
            },
            {
              title: 'Клиенты',
              path: '/lists/clients'
            }
          ]
        },
        {
          title: 'Отчеты',
          img: 'reports.svg',
          margin: 175,
          path: '/reports',
          show: ['Admin'],
          subpage: [
            {
              title: 'Персонал',
              path: '/reports/staff'
            },
            {
              title: 'Клиенты',
              path: '/reports/clients'
            }
          ]
        },
        {
          title: 'Расписание',
          path: '/schedule',
          img: 'schedule.svg',
          show: ['Admin'],
          margin: 225,
        },
        {
          title: 'Обзвоны',
          path: '/call-center',
          img: 'call-center.svg',
          show: ['Admin', 'Оператор'],
          margin: 274,
          subpage: [
            {
              title: 'После 1 визита',
              path: '/call-center/first-visit'
            },
            {
              title: 'Обзвон 2',
              path: '/call-center/Calling2'
            },
            {
              title: 'Обзвон 3',
              path: '/call-center/Calling3'
            }
          ],
        }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.getters.loggedIn.user
    },
    loggedIn() {
      return this.$store.getters.loggedIn
    }
  },
  watch: {
    $route(current) {
      const route = this.pages.find(route => route.path === current.path)
      if (route) {
        this.page = route
      } else if (!route) {
        this.pages.forEach(route => {
          if (route.subpage) return route.subpage.forEach(subpage => {
            if (subpage.path === current.matched[0].path) {
              this.page = route
            }
          })
        })
      }
    },

  },
  created() {
    //refactoring
    const route = this.pages.find(route => route.path === this.$route.path)
    if (route) {
      this.page = route
    } else {
      this.pages.forEach(page => {
        if (page.subpage) {
          page.subpage.forEach(subpages => {
            let routesArray = this.$route.matched[0].path.split('/')
            routesArray = routesArray.slice(1, routesArray.length - 1)
            routesArray.forEach(route => {
              const reg = new RegExp("/\\b" + route + "/\\b", "gi")
              const match = subpages.path.match(reg)
              if (match) this.page = page
            })
          })
        }
      })
    }
  },
  mounted() {
    const accessiblePages = []
    this.loggedIn.roles.forEach(role => {
      this.pages.map(page => {
        if (page.show.includes(role.name)) {
          accessiblePages.push(page)
        }
      })
    })

    if (accessiblePages.length === this.pages.length) return
    accessiblePages.forEach((page, index) => {
      page.margin = 121 + (index * 50)
    })
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
  @import "../assets/main.scss";
</style>

